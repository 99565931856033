import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
let AuthExpiredPage = class AuthExpiredPage extends Vue {
    gotoLicense() {
        this.$router.replace({
            name: 'auth.license',
        });
    }
};
AuthExpiredPage = __decorate([
    Component({})
], AuthExpiredPage);
export default AuthExpiredPage;
